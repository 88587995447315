import { useMemo } from 'react';
import moment, { MomentInput } from 'moment';
import UpgradeSub from './upgradeSub';
import { format } from 'helpers/momentConfig';
import useStateRedux from 'hooks/useStateRedux';
import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Tag,
  Typography,
} from 'antd';
import { DollarOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const Subscription = () => {
  const { licenses, edition, ...rest }: any = useStateRedux('auth');

  const avaliableLicences = useMemo(() => licenses - rest.mailBox, [rest]);

  const expirationDate = useMemo((): MomentInput => {
    if (edition == 'EXPIRED') return rest.purchase_date;
    if (edition == 'TRIAL') return moment(rest.created_at).add(14);
    if (rest.purchase_date) {
      const match = edition.match(/\d+/);
      const monthsToAdd = parseInt(match[0], 10);
      return moment(rest.purchase_date)
        .add(monthsToAdd, 'months')
        .format('YYYY-MM-DD');
    }
    return null;
  }, [rest]);

  const nextChargeDate = useMemo((): MomentInput => {
    if (['EXPIRED', 'TRIAL'].includes(edition)) return null;
    if (rest.purchase_date) {
      const match = edition.match(/\d+/);
      const monthsToAdd = parseInt(match[0], 10);
      return moment(rest.purchase_date)
        .add(monthsToAdd, 'months')
        .format('YYYY-MM-DD');
    }
    return null;
  }, [rest]);

  const maxContentWidth = 1400;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: 16,
        boxSizing: 'border-box',
        width: '100%',
      }}
    >
      <div
        style={{
          width: '100%',
          maxWidth: maxContentWidth,
        }}
      >
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Card
              title={
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Subscription Information
                </Typography.Title>
              }
              bordered
              bodyStyle={{ padding: 24 }}
            >
              <Descriptions
                layout="vertical"
                bordered
                size="middle"
                column={{ xs: 1, sm: 1, md: 2 }}
                labelStyle={{
                  fontWeight: 600,
                  color: '#5a5a5a',
                  backgroundColor: '#fafafa',
                  padding: '8px 12px',
                }}
                contentStyle={{
                  color: '#222',
                  fontWeight: 500,
                  padding: '8px 12px',
                }}
              >
                <Descriptions.Item label="Current Plan">
                  <Tag color="blue" style={{ fontSize: 14 }}>
                    {edition}
                  </Tag>
                </Descriptions.Item>

                <Descriptions.Item label="Payment Method">
                  {rest.payment_method || 'N/A'}
                </Descriptions.Item>

                <Descriptions.Item label="Expiration Date">
                  <span>{moment(expirationDate).format(format)}</span>
                </Descriptions.Item>

                <Descriptions.Item label="Next Charge">
                  {nextChargeDate ? (
                    <span style={{ color: '#1890ff' }}>
                      {moment(nextChargeDate).format(format)}
                    </span>
                  ) : (
                    'N/A'
                  )}
                </Descriptions.Item>

                <Descriptions.Item label="Licenses">
                  {licenses}{' '}
                  <Tag
                    color={avaliableLicences >= 0 ? '#52c41a' : '#ff4d4f'}
                    style={{ fontSize: 14, marginLeft: 8 }}
                  >
                    {avaliableLicences >= 0
                      ? `Available: ${avaliableLicences}`
                      : `You need to pay ${Math.abs(avaliableLicences)}`}
                  </Tag>
                </Descriptions.Item>

                <Descriptions.Item label="Mail Boxes">
                  {rest.mailBox}
                </Descriptions.Item>
              </Descriptions>

              {edition !== 'TRIAL' && avaliableLicences < 0 && (
                <Alert
                  type="error"
                  showIcon
                  icon={<ExclamationCircleOutlined />}
                  style={{ marginTop: 24 }}
                  message={
                    <span>
                      <b>Note:</b> You need to pay licenses for all
                      collaborators from your Google Workspace (G Suite) Account
                    </span>
                  }
                  description={
                    <div style={{ marginTop: 12 }}>
                      <Button
                        type="primary"
                        danger
                        icon={<DollarOutlined />}
                        size="large"
                        block
                        style={{ fontWeight: 600 }}
                        onClick={() => {
                          window.location.href = `${process.env.REACT_APP_AUTH_ACCOUNT_HOST}/checkout`;
                        }}
                      >
                        Pay Now
                      </Button>
                    </div>
                  }
                />
              )}
            </Card>
          </Col>

          <Col span={24}>
            <UpgradeSub />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Subscription;

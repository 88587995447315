import apiV1 from 'config/axios/v1.instance';
import { initSignatureList } from '../initialStates';
import { useCallback, useEffect, useState } from 'react';
import {
  IExecutionModel,
  IExecutionWithDetails,
} from 'models/api/signature.model';

export const useFetchExecutionList = ({
  page = 1,
  signatureId,
}: IEntityParams) => {
  // definition state
  const [data, setData] = useState<IExecutionModel>(initSignatureList as any);
  const [state, setState] = useState({ loading: true, error: null });

  // get data server api
  const getDataServer = useCallback(() => {
    apiV1
      .get<IExecutionModel>(
        `/executions?page=${page}&limit=30&signature_id=${signatureId}&sort=created_date,desc`,
      )
      .then(({ data }) => {
        setData(data);
        setState((prev) => ({ ...prev, loading: false }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
          error: !!err?.response ? err?.response?.data : err?.message,
        }));
      });
  }, [page, signatureId]);

  useEffect(() => {
    getDataServer();
  }, [getDataServer]);

  // return state
  return { data, ...state, refresh: getDataServer };
};

export const useFetchExecutionDetailsList = (executionId: string) => {
  // definition state
  const [data, setData] = useState<IExecutionWithDetails>({} as any);
  const [state, setState] = useState({ loading: true, error: null });

  // get data server api
  const getDataServer = useCallback(() => {
    apiV1
      .get<IExecutionWithDetails>(`/executions/${executionId}`)
      .then(({ data }) => {
        setData(data);
        setState((prev) => ({ ...prev, loading: false }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
          error: !!err?.response ? err?.response?.data : err?.message,
        }));
      });
  }, [executionId]);

  useEffect(() => {
    getDataServer();
  }, [getDataServer]);

  // return state
  return { data, ...state, refresh: getDataServer };
};

interface IEntityParams {
  page?: any;
  limit?: number;
  signatureId: string;
}

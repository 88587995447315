import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import Editor from 'components/editor/editor';
import useStateRedux from 'hooks/useStateRedux';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { handleFormSignature } from 'redux/actions/forms.action';
import ModalMarketPlace from '../../../components/marketplace/ModalMarketPlace';

const { Text } = Typography;

const InformationForm = ({ visible, setVisible, handleModal }: IFormProps) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { signature }: any = useStateRedux('forms');

  const onChangeEditor = (body: any) => {
    dispatch(handleFormSignature({ body }));
    setVisible(false);
  };
  const onValuesChange = (payload: any) =>
    dispatch(handleFormSignature(payload));
  // const handleActive = (e: any) => dispatch(handleFormSignature({active: e.target.checked}))

  useEffect(() => {
    form.setFieldsValue(signature);
  }, [signature, form]);

  return (
    <div className="animate__animated animate__fadeIn mt-1">
      <Text className="text-secondary">
        Create or edit the signature to be set to user in your domain.
      </Text>
      <Form
        onValuesChange={onValuesChange}
        form={form}
        className="mt-2"
        layout="vertical"
      >
        <Row gutter={20}>
          <Col xs={24} md={12}>
            <Form.Item name="name" label="Name">
              <Input placeholder="Name signature" size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="own-theme" label="Choose from you own template">
              <Button
                type="dashed"
                className="btn-primary"
                size="large"
                onClick={handleModal}
              >
                Select template from gallery
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Editor data={signature.body} onChangeEditor={onChangeEditor} />
      <ModalMarketPlace
        visible={visible}
        onCancel={handleModal}
        okText="Apply"
        onTemplate={onChangeEditor}
      />
    </div>
  );
};

interface IFormProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  handleModal: () => void;
}

export default InformationForm;

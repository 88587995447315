import { CheckCircleTwoTone, CreditCardOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Modal, Row, Select, Typography } from 'antd';
import useStateRedux from 'hooks/useStateRedux';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { calculateTotalPricing } from 'utils/calculateTotalPricing';

const { Title, Text } = Typography;

export const PurchasePlanModal = ({
  visible,
  handleVisible,
  href,
  title,
  handleButtonClick,
  pricing,
}: PurchasePlanModalProps) => {
  const { mailBox }: any = useStateRedux('auth');

  const [selected, setSelected] = useState<'monthly' | 'annually'>('annually');
  const [paymentSelected, setPaymentSelected] = useState<'card'>('card');

  return (
    <Modal
      width={1200}
      visible={visible}
      onCancel={handleVisible}
      footer={null}
    >
      <Row gutter={[0, 24]} style={{ marginTop: '10px' }}>
        <Col xs={24} md={12}>
          <Card
            style={{
              height: '100%',
              borderRadius: 8,
              borderRight: 'none',
            }}
            bodyStyle={{ padding: 24 }}
          >
            <Title level={3}>Purchase plan</Title>
            <Text>
              Upgrading Zumpul's Plan to{' '}
              <Title level={5} style={{ display: 'inline' }}>
                {title}
              </Title>
            </Text>

            <div style={{ marginTop: 16 }}>
              <Select
                value={`${mailBox} members`}
                style={{ width: 160 }}
                disabled
              />
            </div>

            <Card
              style={{
                marginTop: 24,
                marginBottom: 24,
              }}
              bodyStyle={{ padding: 20 }}
            >
              <Typography.Title level={5}>Billing plan</Typography.Title>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
                <Card
                  hoverable
                  onClick={() => setSelected('annually')}
                  style={{
                    flex: 1,
                    minWidth: 140,
                    border:
                      selected === 'annually'
                        ? '2px solid #3ca898'
                        : '1px solid #f0f0f0',
                    borderRadius: 8,
                    cursor: 'pointer',
                    position: 'relative',
                    transition: 'all 0.3s ease',
                  }}
                  bodyStyle={{ padding: 20, textAlign: 'center' }}
                >
                  <CheckCircleTwoTone
                    twoToneColor={
                      selected === 'annually' ? '#3ca898' : '#f0f0f0'
                    }
                    style={{ fontSize: 20 }}
                  />
                  <Title level={2}>
                    <span>
                      {pricing.annually.integer}
                      <small>.{pricing.annually.decimal} US$</small>
                    </span>
                  </Title>
                  <Typography style={{ marginBottom: 8 }}>
                    Member / Annually
                  </Typography>
                </Card>

                <Card
                  hoverable
                  onClick={() => setSelected('monthly')}
                  style={{
                    flex: 1,
                    minWidth: 140,
                    border:
                      selected === 'monthly'
                        ? '2px solid #3ca898'
                        : '1px solid #f0f0f0',
                    borderRadius: 8,
                    cursor: 'pointer',
                    position: 'relative',
                    transition: 'all 0.3s ease',
                  }}
                  bodyStyle={{ padding: 20, textAlign: 'center' }}
                >
                  <CheckCircleTwoTone
                    twoToneColor={
                      selected === 'monthly' ? '#3ca898' : '#f0f0f0'
                    }
                    style={{ fontSize: 20 }}
                  />
                  <Title level={2}>
                    <span>
                      {pricing.monthly.integer}
                      <small>.{pricing.monthly.decimal} US$</small>
                    </span>
                  </Title>
                  <Typography style={{ marginBottom: 8 }}>
                    Member / Monthly
                  </Typography>
                </Card>
              </div>
            </Card>

            <Card
              style={{
                marginTop: 24,
                marginBottom: 24,
              }}
              bodyStyle={{ padding: 20 }}
            >
              <Typography.Title level={5}>Payment method</Typography.Title>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
                <Card
                  hoverable
                  onClick={() => setPaymentSelected('card')}
                  style={{
                    width: 140,
                    border:
                      paymentSelected === 'card'
                        ? '2px solid #3ca898'
                        : '1px solid #f0f0f0',
                    borderRadius: 8,
                    cursor: 'pointer',
                    position: 'relative',
                    textAlign: 'center',
                    transition: 'all 0.3s ease',
                  }}
                  bodyStyle={{ padding: 20 }}
                >
                  <CreditCardOutlined
                    style={{ fontSize: 32, color: '#3ca898' }}
                  />
                  <div style={{ marginTop: 8, fontSize: 14 }}>Card</div>
                  {paymentSelected === 'card' && (
                    <CheckCircleTwoTone
                      twoToneColor="#3ca898"
                      style={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        fontSize: 18,
                      }}
                    />
                  )}
                </Card>
              </div>
            </Card>
          </Card>
        </Col>

        <Col xs={24} md={12}>
          <Card
            style={{
              height: '100%',
              borderRadius: 8,
              borderLeft: 'none',
              backgroundColor: '#fafafa',
            }}
            bodyStyle={{ padding: 24 }}
          >
            <Title level={3}>Order Summary</Title>

            <Row justify="space-between" style={{ marginBottom: 16 }}>
              <Col>
                <Text strong>{title} plan</Text>
                <br />
                <Text type="secondary">
                  {`$${pricing[selected].integer}.${
                    pricing[selected].decimal
                  } x ${mailBox} members x ${
                    selected === 'monthly' ? '1 month' : '12 months'
                  } `}
                </Text>
              </Col>
              <Col>
                <Text strong>
                  $
                  {calculateTotalPricing(
                    pricing[selected].integer,
                    pricing[selected].decimal,
                    mailBox,
                  ).toFixed(2)}
                </Text>
              </Col>
              <Col>
                <Text>Have coupon code?</Text>
                <Input
                  placeholder="Enter coupon code"
                  bordered={false}
                  style={{
                    borderBottom: '1px solid #000',
                  }}
                />
              </Col>
            </Row>

            <Row justify="space-between">
              <Col>
                <Text strong>Total due today</Text>
              </Col>
              <Col>
                <Text strong style={{ fontSize: 18 }}>
                  $
                  {calculateTotalPricing(
                    pricing[selected].integer,
                    pricing[selected].decimal,
                    mailBox,
                  ).toFixed(2)}
                </Text>
              </Col>
            </Row>

            <div className="action-button-container">
              <Link
                to={{ pathname: href }}
                onClick={handleButtonClick}
                target="_blank"
              >
                <Button type="primary" size="large" block>
                  Upgrade
                </Button>
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

interface PurchasePlanModalProps {
  visible: boolean;
  handleVisible: () => void;
  title: string;
  href: string;
  handleButtonClick: (event: React.MouseEvent) => void;
  pricing: {
    monthly: {
      integer: number;
      decimal: number;
    };
    annually: {
      integer: number;
      decimal: number;
    };
  };
}

export default PurchasePlanModal;

import React, { useState } from 'react';
import AdvanceConfig from './advanceConfig';
import InformationForm from './informationForm';
import ChoosePeopleForm from './choosePeopleForm';

const CurrentCreateSignatureView = ({ active, isEditing }: IActionProps) => {
  const [visible, setVisible] = useState(!isEditing);

  const handleModal = () => setVisible(!visible);
  switch (active) {
    case 'information':
      return (
        <InformationForm
          visible={visible}
          setVisible={setVisible}
          handleModal={handleModal}
        />
      );
    case 'choosePeople':
      return <ChoosePeopleForm />;
    case 'advanceConfig':
      return <AdvanceConfig />;

    default:
      return (
        <InformationForm
          visible={visible}
          setVisible={setVisible}
          handleModal={handleModal}
        />
      );
  }
};

interface IActionProps {
  active: 'information' | 'choosePeople' | 'advanceConfig' | string;
  isEditing: boolean;
}

export default CurrentCreateSignatureView;

import React from 'react';
import { Typography } from 'antd';
import Subscription from './components/subscription';

const { Title } = Typography;

const SubscriptionPage = () => {
  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{ minHeight: '75vh', position: 'relative' }}
    >
      <Title level={1} style={{ marginBlockEnd: 0 }}>
        Subscription
      </Title>
      <Subscription />
    </div>
  );
};

export default SubscriptionPage;

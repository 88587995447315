export const calculateTotalPricing = (
  integer: number,
  decimal: number,
  quantity: number,
): number => {
  return parseToDecimal(integer, decimal) * quantity;
};

export const parseToDecimal = (integer: number, decimal: number) => {
  return Number(`${integer}.${decimal.toString().padStart(2, '0')}`);
};

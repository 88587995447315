import { Card, Typography } from 'antd';
import { useSignatureByPersonId } from 'api/person/person.api';
import Loading from 'components/loading/loading';

interface PreviewTemplateProps {
  peopleId: string;
}

const PreviewTemplate = ({ peopleId }: PreviewTemplateProps) => {
  const { data, loading, error } = useSignatureByPersonId(peopleId);

  const renderSignature = () => {
    if (loading) return <Loading />;
    if (error)
      return (
        <Typography.Text type="danger">
          Error loading signature.
        </Typography.Text>
      );
    if (!data?.signature)
      return (
        <Typography.Text type="secondary">No signature found.</Typography.Text>
      );

    return (
      <div
        className="preview-content"
        dangerouslySetInnerHTML={{ __html: data.signature }}
      />
    );
  };

  return (
    <Card className="email-preview-container">
      <div className="email-header">
        <div className="email-buttons">
          <span className="red" />
          <span className="yellow" />
          <span className="green" />
        </div>
      </div>
      <div className="email-content">
        <Typography.Text className="email-to">To: Darvin Otero</Typography.Text>
        <Typography.Text className="email-subject">
          Subject: Check out my new email signature!
        </Typography.Text>
        <div className="email-body">
          <Typography.Paragraph className="email-body-color">
            Hi Darvin,
          </Typography.Paragraph>
          <Typography.Paragraph className="email-body-color">
            Check out this awesome email signature:
          </Typography.Paragraph>
        </div>
        <div className="email-body-preview">{renderSignature()}</div>
      </div>
    </Card>
  );
};

export default PreviewTemplate;

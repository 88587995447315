import apiV1 from 'config/axios/v1.instance';
import { initSignatureList } from '../initialStates';
import { useCallback, useEffect, useState } from 'react';
import {
  IPeopleFilters,
  ISignatureByPersonModel,
  ISignatureListModel,
} from 'models/api/signature.model';
import axiosPassport from 'config/axios/v1Passport.instance';
import { generateParams } from 'helpers/filterParams';

export const useFetchPeopleList = (filters = { page: 1 } as IPeopleFilters) => {
  // definition state
  const [data, setData] = useState<ISignatureListModel>(initSignatureList);
  const [state, setState] = useState({ loading: true, error: null });

  const filter = generateParams(filters);

  // get data server api
  const getDataServer = useCallback(() => {
    setState({ loading: true, error: null });
    apiV1
      .get<ISignatureListModel>(`/people${filter}&limit=30`)
      .then(({ data }) => {
        setData(data);
        setState((prev) => ({ ...prev, loading: false }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
          error: !!err?.response ? err?.response?.data : err?.message,
        }));
      });
  }, [filters]);

  useEffect(() => {
    getDataServer();
  }, [filters, getDataServer]);

  // return state
  return { data, ...state, refresh: getDataServer };
};

export const getByIdProfile = (id: string | number | undefined) => {
  // get data server api
  return apiV1
    .get(`/people/${id}`)
    .then(({ data }) => {
      return { data, err: null };
    })
    .catch((error) => {
      console.log(error?.response?.data || error);
      return { data: null, err: error?.response?.data || error };
    });
};

export const useSignatureByPersonId = (id: string) => {
  const [data, setData] = useState<ISignatureByPersonModel | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!id) return;

    const fetchSignature = async () => {
      try {
        setLoading(true);
        setError(null);
        const res = await apiV1.get<ISignatureByPersonModel[]>(
          `/people/${id}/signatures`,
        );
        setData(res.data[0] || null);
      } catch (err: any) {
        setError(err?.response?.data || err?.message || 'Unknown error');
        setData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchSignature();
  }, [id]);

  return { data, loading, error };
};

export const updateProfileById = (id: string | number, body: any) => {
  // get data server api
  return apiV1
    .put(`/people/${id}`, body)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error?.response?.data || error);
      return false;
    });
};

export const syncPeople = (id: string, modules = ['users']) => {
  return axiosPassport
    .get(`/customers/sync/${id}`, {
      params: Object.fromEntries(modules.map((x) => [x, true])),
    })
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error?.response?.data || error);
      return false;
    });
};

import { Row, Col, Switch } from 'antd';
import CardPlans from 'components/cardPlans/cardPlans';
import useStateRedux from 'hooks/useStateRedux';
import { useState } from 'react';

const Plans = ({ data }: IPlans) => {
  const { edition }: any = useStateRedux('auth');

  const [currentTime, setCurrentTime] = useState<'monthly' | 'annually'>(
    'annually',
  );

  const handleSwitchChange = (checked: boolean) => {
    setCurrentTime(checked ? 'annually' : 'monthly');
  };

  const filteredPlans = data.filter((card: any) => {
    if (edition === 'ZPRO1M' || edition === 'ZPRO12M') {
      return card.id !== 'trial';
    }

    if (edition === 'ZENT1M' || edition === 'ZENT12M') {
      return card.id !== 'trial' && card.id !== 'pro';
    }

    return true;
  });

  return (
    <div style={{ maxWidth: '1400px', margin: '0 auto', padding: '0 16px' }}>
      <Row gutter={[24, 24]} justify="center">
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Switch
            checked={currentTime === 'annually'}
            onChange={handleSwitchChange}
            checkedChildren="Annually"
            unCheckedChildren="Monthly"
          />
        </Col>
        {filteredPlans.map((plan: any) => (
          <Col
            key={plan.id}
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={6}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <CardPlans {...plan} currentTime={currentTime} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

interface IPlans {
  data: any;
  handleActive?: (id: any) => void;
}

export default Plans;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, List, Typography, message } from 'antd';
import { useDispatch } from 'react-redux';
import {
  setGoogleAuthenticate,
  updatePlan,
} from '../../redux/actions/auth.action';
import { CheckCircleOutlined } from '@ant-design/icons';
import useStateRedux from 'hooks/useStateRedux';
import ReuseModal from 'components/modal/reuseModal';
import getCurrentPayment, {
  transformPlanToEdition,
} from 'utils/getCurrentPayment';
import { changeCurrentPlan } from 'api/subscription/subscription.api';

import PurchasePlanModal from './PurchasePlanModal';

const { Title } = Typography;

interface PlanProps {
  title: string;
  pricing: {
    monthly: {
      integer: number;
      decimal: number;
      code: string;
    };
    annually: {
      integer: number;
      decimal: number;
      code: string;
    };
  };
  href: string;
  advantage: string[];
  okText?: string;
  onClick?: (title: string, currentMembership?: string) => void;
  buttonClick?: (title: string, currentMembership: string, e: any) => void;
  isVisibleModal: () => void;
  head?: string;
  currentTime: 'monthly' | 'annually';
}

const PlanCard = ({
  title,
  pricing,
  href,
  advantage,
  okText = 'Select Plan',
  onClick,
  buttonClick,
  head,
  currentTime,
}: PlanProps) => {
  const dispatch = useDispatch();
  const { edition }: any = useStateRedux('auth');

  const [loading, setLoading] = useState(false);

  const isCurrentPlan =
    edition === pricing.monthly.code || edition === pricing.annually.code;
  const hasPricing =
    !!pricing[currentTime].integer || !!pricing[currentTime].decimal;

  const handleButtonClick = (e: React.MouseEvent) => {
    if (buttonClick) return buttonClick(title, currentTime, e);
    dispatch(
      setGoogleAuthenticate({ plan: title, currentMembership: currentTime }),
    );
  };

  const [visible, setVisible] = useState(false);

  const handleVisible = () => setVisible(!visible);

  const onOk = async () => {
    const plan = getCurrentPayment(title, currentTime);
    const edition = transformPlanToEdition(title, currentTime);

    if (edition !== null) {
      setLoading(true);
      try {
        const success = await changeCurrentPlan(plan);

        if (!success) {
          message.error('Update plan failed');
        } else {
          message.success('Your subscription was successfully updated.');
          dispatch(updatePlan(title, currentTime, edition));
          handleVisible();
        }
      } catch (error) {
        message.error('Please try again or try again later.');
      } finally {
        setLoading(false);
      }
    } else {
      message.error(
        "We couldn't update your subscription at this time. Please try again later or contact support if the issue persists",
      );
    }
  };

  return (
    <>
      <Card
        onClick={() => onClick?.(title, currentTime)}
        style={{
          marginBottom: 32,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxWidth: 600,
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title level={3} style={{ margin: 0 }}>
            {title}
          </Title>
          {isCurrentPlan && (
            <span className="current-plan-badge">Current Plan</span>
          )}
        </div>

        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: 0,
          }}
        >
          <div
            style={{
              minHeight: 60,
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            {hasPricing ? (
              <Title level={2} style={{ textAlign: 'left', margin: 0 }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  <span>
                    {pricing[currentTime].integer}
                    <small>.{pricing[currentTime].decimal} US$</small>
                  </span>
                  <span className="pricing-period">
                    / {currentTime === 'annually' ? 'year' : 'month'}
                  </span>
                </div>
              </Title>
            ) : (
              <Title level={5} className="plan-head" style={{ margin: 0 }}>
                {head}
              </Title>
            )}
          </div>

          <div className="divider" />

          <div style={{ marginBottom: '10px', flexShrink: 0, height: '56px' }}>
            {title !== 'Trial' && (
              <div>
                {title === 'Education' ? (
                  <Link
                    to={{ pathname: href }}
                    onClick={handleButtonClick}
                    target="_blank"
                  >
                    <Button type="primary" size="large" block>
                      {okText}
                    </Button>
                  </Link>
                ) : (
                  <Button
                    type="primary"
                    size="large"
                    block
                    onClick={handleVisible}
                    disabled={loading}
                  >
                    {okText}
                  </Button>
                )}
              </div>
            )}
          </div>

          <Title level={5} style={{ textAlign: 'left', marginBottom: 8 }}>
            <strong>{title}</strong> plan includes:
          </Title>

          <List
            dataSource={advantage}
            renderItem={(item) => (
              <List.Item style={{ textAlign: 'left' }}>
                <CheckCircleOutlined className="check-icon" />
                {item}
              </List.Item>
            )}
            style={{ flex: 1, overflow: 'hidden' }}
          />
        </div>
      </Card>

      {edition === 'TRIAL' ? (
        <PurchasePlanModal
          visible={visible}
          handleButtonClick={handleButtonClick}
          handleVisible={handleVisible}
          href={href}
          title={title}
          pricing={pricing}
        />
      ) : (
        <ReuseModal
          visible={visible}
          onCancel={handleVisible}
          onOk={onOk}
          okText="Confirm Upgrade"
          cancelText="Review Details"
          confirmLoading={loading}
        >
          <div style={{ color: '#262626' }}>
            <h3
              style={{
                marginBottom: 24,
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              Upgrade Confirmation
            </h3>

            <p style={{ fontSize: 16, marginBottom: 24 }}>
              You're aRowt to upgrade to the{' '}
              <span style={{ fontWeight: 600 }}>{title}</span> plan for a{' '}
              <span style={{ fontWeight: 600 }}>{currentTime}</span> period.
              Please review the following changes:
            </p>

            <List
              itemLayout="horizontal"
              dataSource={[
                {
                  title: 'Billing Update:',
                  content:
                    'Your payment method will be charged according to the new plan.',
                },
                {
                  title: 'Prorated Adjustment:',
                  content: (
                    <span>
                      Total amount due for this cycle:
                      <span
                        style={{
                          fontWeight: 600,
                          marginLeft: 8,
                        }}
                      >
                        ({currentTime})
                      </span>
                    </span>
                  ),
                },
                {
                  title: 'Email Notifications:',
                  content: (
                    <ul
                      style={{ margin: '8px 0 0 16px', listStyleType: 'none' }}
                    >
                      <li style={{ marginBottom: 8 }}>
                        ✓ Upgrade confirmation
                      </li>
                      <li>✓ Updated invoice details</li>
                    </ul>
                  ),
                },
              ]}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <span style={{ fontWeight: 600 }}>{item.title}</span>
                    }
                    description={item.content}
                  />
                </List.Item>
              )}
            />

            <Alert
              message="Important"
              description="Changes will take effect immediately."
              type="warning"
              showIcon
              style={{ marginTop: 24 }}
              banner
            />
          </div>
        </ReuseModal>
      )}
    </>
  );
};

export default PlanCard;

import axiosPassport from 'config/axios/v1Passport.instance';
import { getStorage } from '../../helpers/storage';
const getToken = () => {
  const auth: any = getStorage('auth');
  if (!!auth?.token) return auth.token;
  return '';
};

const headers = () => ({
  Authorization: `Bearer ${getToken()}`,
  Accept: 'application/json',
});

export const changeCurrentPlan = async (
  plan_code: string,
): Promise<boolean> => {
  return axiosPassport
    .patch(
      '/subscription/plan',
      {
        plan_code,
      },
      {
        headers: headers(),
      },
    )
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error?.response?.data || error);
      return false;
    });
};

import { Col, Row, Typography } from 'antd';
import Plans from 'pages/settings/components/plans';
import { dataPricingSimplify } from 'utils/utils';

const { Title, Paragraph } = Typography;

const UpgradeSub = () => {
  return (
    <div style={{ width: '100%', padding: '24px 0' }}>
      <Row justify="center">
        <Col
          xs={24}
          sm={22}
          md={20}
          lg={16}
          xl={14}
          style={{ textAlign: 'center' }}
        >
          <Title level={2} style={{ marginBottom: 8 }}>
            Upgrade Plan
          </Title>
          <Paragraph style={{ fontSize: 16, color: '#595959' }}>
            Select the plan that best suits your needs and proceed with the
            upgrade.
          </Paragraph>
        </Col>
      </Row>

      <div>
        <Plans data={dataPricingSimplify} />
      </div>
    </div>
  );
};

export default UpgradeSub;
